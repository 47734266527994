import React, {useEffect, useState} from "react";
import Layout from "./Screens/Layout";
import {Routes, Route} from "react-router-dom";
import './App.css';

//theme
import {ThemeProvider, createTheme} from '@mui/material/styles';
import axios from "axios";
import Cookies from 'js-cookie';
import * as constantes from "./Screens/Parts/AlveoleConstants";
import {toast} from "react-toastify";


//theme
const ColorModeContext = React.createContext({
    toggleColorMode: () => {
    }
});

const Profile = React.lazy(() => import("./Screens/Profile"));
const ResetPassword = React.lazy(() => import("./Screens/ResetPassword"));
const LegalMentions = React.lazy(() => import("./Screens/LegalMentions"));
const PhoneModification = React.lazy(() => import("./Screens/PhoneModification"));
const App = () => {
    //theme
    const [mode, setMode] = React.useState(Cookies.get('theme'));
    const [popupMessage, setPopupMessage] = useState(false);
    const [popupMessageErr, setPopupMessageErr] = useState(false);
    const [notification, updateNotification] = useState(false);

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
                const userLog = Cookies.get('idUser');

                axios.post(`${constantes.urlApi}/theme`,
                    {
                        userLog: userLog,
                        theme: (mode === 'light' ? 'dark' : 'light')
                    },
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        }
                    })
                    .then(response => {
                        toast.success("Le theme a été mis à jour.", {
                            progressStyle: {
                                backgroundColor: '#00C2EF',
                                autoClose: 3000
                            }
                        });
                        Cookies.set('theme', response.data['theme'], {domain: constantes.domain});
                    })
                    .catch(error => {
                        toast.error("Erreur lors du changement de thème: " + error, {autoClose: 1000});
                    });
            },
        }),
        [mode, setMode],
    );

    useEffect(() => {
        if (Cookies.get('theme')) {
            setMode(Cookies.get('theme'));
        } else {
            setMode('dark'); // thème par défaut
        }
        if (document.body.classList.contains('dark-theme')) {
            document.body.classList.remove('dark-theme');
        } else {
            document.body.classList.remove('light-theme');
        }
        if (Cookies.get('theme') === 'dark') {
            document.body.classList.add('dark-theme');
        } else {
            document.body.classList.add('light-theme');
        }
    }, []);

    const theme = React.useMemo(
        () =>
            createTheme({
                components: {
                    MuiButton: {
                        styleOverrides: {
                            root: {
                                color: 'inherit', // Hérite de la couleur du parent
                                backgroundColor: 'transparent', // Fond transparent
                                boxShadow: 'none', // Pas d'ombre
                                '&:hover': {
                                    backgroundColor: 'transparent', // Fond transparent au survol
                                    boxShadow: 'none', // Pas d'ombre au survol
                                },
                                '&:active': {
                                    boxShadow: 'none', // Pas d'ombre en état actif
                                },
                                '&:focus': {
                                    boxShadow: 'none', // Pas d'ombre en état focus
                                },
                            },
                        },
                        MuiFormControlLabel: {
                            styleOverrides: {
                                root: {
                                    margin: 0, // Enlève le margin
                                },
                            },
                        },
                    },
                },
                palette: {
                    mode,
                },
                accordion: {
                    backgroundImage: 'none',
                },
                accordionSummary: {
                    backgroundImage: 'none',
                },
                typography: {
                    fontFamily: 'var(--font-family-app)'
                },
            }),
        [mode],
    );
    //theme

    React.useEffect(() => {
        // 👇 add class to body element
        if (document.body.classList.contains('dark-theme')) {
            document.body.classList.remove('dark-theme');
        } else {
            document.body.classList.remove('light-theme');
        }
        if (theme.palette.mode === 'dark') {
            document.body.classList.add('dark-theme');
        } else {
            document.body.classList.add('light-theme');
        }
    }, [theme]);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <Layout notification={notification}>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <React.Suspense fallback={<>...</>}>
                                    <Profile mode={mode} colorMode={colorMode}
                                             popupMessage={popupMessage}
                                             setPopupMessage={setPopupMessage}
                                             popupMessageErr={popupMessageErr}
                                             setPopupMessageErr={setPopupMessageErr}/>
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="modifier-mot-de-passe"
                            element={
                                <React.Suspense fallback={<>...</>}>
                                    <ResetPassword setPopupMessage={setPopupMessage}
                                                   setPopupMessageErr={setPopupMessageErr}/>
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="modifier-telephone"
                            element={
                                <React.Suspense fallback={<>...</>}>
                                    <PhoneModification setPopupMessage={setPopupMessage}
                                                       setPopupMessageErr={setPopupMessageErr}/>
                                </React.Suspense>
                            }
                        />
                        <Route
                            path="mentions-legales"
                            element={
                                <React.Suspense fallback={<>...</>}>
                                    <LegalMentions/>
                                </React.Suspense>
                            }
                        />
                    </Routes>
                </Layout>
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
        ;
}

export default App;
